import { inject, Injectable } from '@angular/core';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { LicenseService } from '../services/license.service';
import { createEffect, ofType } from '@ngrx/effects';
import { LicensesApiActions } from './licenses.actions';
import { map, switchMap, withLatestFrom } from 'rxjs';
import { Action } from '@ngrx/store';
import * as OperatorsSelectors from '../../operators/store/operators.selector';
import { filterNotNull } from '../../../shared/utility/rxjs-utils';

@Injectable({ providedIn: 'any' })
export class LicensesEffects extends NgRxEffectsBase {

  readonly #licenseService = inject(LicenseService);

  public getAllAssignedUsers$ = createEffect(() => this.actions$.pipe(
    ofType(LicensesApiActions.getAllAssignedUsers),
    withLatestFrom(this.store.select(OperatorsSelectors.loggedOperator)),
    filterNotNull,
    switchMap(([, ope]) => this.#licenseService.getAllAssignedUsers(ope.customer.licenseCode).pipe(
      map(users => LicensesApiActions.getAllAssignedUsersSuccess({ users })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(LicensesApiActions.getAllAssignedUsersFailure({ httpStatus: null }))
    ))
  ));

}
