import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { routerExtraReducer, RouterExtraState } from '../router/store/router.reducer';
import { operatorsReducer, OperatorsState } from '../operators/store/operators.reducer';
import { customersReducer, CustomersState } from '../customers/store/customers.reducer';
import { deviceTypesReducer, DeviceTypesState } from '../device-types/store/device-types.reducer';
import { functionsReducer, FunctionsState } from '../functions/store/functions.reducer';
import { ticketCategoriesReducer, TicketCategoriesState } from '../ticket-categories/store/ticket-categories.reducer';
import { umReducer, UMState } from '../um/store/um.reducer';
import { costCenterReducer, CostCenterState } from '../cost-center/store/cost-center.reducer';
import {
  costAllocationTypesReducer,
  CostAllocationTypesState,
} from '../cost-allocation-types/store/cost-allocation-types.reducer';
import { roleReducer, RoleState } from '../role/store/role.reducer';
import { dashboardReducer, DashboardState } from '../dashboard/store/dashboard.reducer';
import {
  datiPersonalizzatiReducer,
  DatiPersonalizzatiState,
} from '../dato-personalizzato/store/dato-personalizzato.reducer';
import { priorityReducer, PriorityState } from '../priority/store/priority.reducer';
import { ticketStatesReducer, TicketStatesState } from '../ticket-states/store/ticket-states.reducer';
import { jobTypesReducer, JobTypesState } from '../job/store/job-types/job-types.reducer';
import { documentTypeReducer, DocumentTypeState } from '../documents/store/document-type.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { AuthEffects } from '../auth/store/auth.effects';
import { RouterEffects } from '../router/store/router.effects';
import { OperatorsEffect } from '../operators/store/operators.effect';
import { CustomersEffect } from '../customers/store/customers.effect';
import { DeviceTypesEffect } from '../device-types/store/device-types.effect';
import { FunctionsEffect } from '../functions/store/functions.effect';
import { TicketCategoriesEffect } from '../ticket-categories/store/ticket-categories.effect';
import { UmEffect } from '../um/store/um.effect';
import { CostCenterEffect } from '../cost-center/store/cost-center.effect';
import { RoleEffect } from '../role/store/role.effect';
import { DashboardEffect } from '../dashboard/store/dashboard.effect';
import { CostAllocationTypesEffect } from '../cost-allocation-types/store/cost-allocation-types.effect';
import { DatoPersonalizzatoEffect } from '../dato-personalizzato/store/dato-personalizzato.effect';
import { PriorityEffects } from '../priority/store/priority.effects';
import { TicketStatesEffect } from '../ticket-states/store/ticket-states.effect';
import { JobTypesEffects } from '../job/store/job-types/job-types.effects';
import { DocumentTypeEffects } from '../documents/store/document-type.effects';
import { licensesReducer, LicensesState } from '../license/store/licenses.reducer';
import { settingsCoreReducer, SettingsCoreState } from '../settings/store/settings-core.reducer';
import { LicensesEffects } from '../license/store/licenses.effects';
import { SettingsCoreEffect } from '../settings/store/settings-core.effect';
import { widgetReducer, WidgetState } from '../widget/store/widget.reducer';
import { WidgetEffects } from '../widget/store/widget.effects';
import { CoreEffects } from './core.effects';
import { itemTypesReducer, ItemTypesState } from '../items/store/item-types.reducer';
import { ItemTypesEffects } from '../items/store/item-types.effects';
import { coreReducer, CoreState } from "./core.reducer";


export interface AppGlobalState {
  core: CoreState
  router: RouterReducerState
  routerExtra: RouterExtraState
  operators: OperatorsState
  customers: CustomersState
  deviceTypes: DeviceTypesState
  functions: FunctionsState
  ticketCategories: TicketCategoriesState
  um: UMState
  costCenter: CostCenterState
  costAllocationTypes: CostAllocationTypesState
  role: RoleState
  dashboard: DashboardState
  datiPersonalizzati: DatiPersonalizzatiState
  priorities: PriorityState
  ticketStates: TicketStatesState
  jobTypes: JobTypesState
  documentType: DocumentTypeState
  licenses: LicensesState
  widgets: WidgetState
  'settings-core': SettingsCoreState
  'item-types': ItemTypesState
}

export const StoreReducers: ActionReducerMap<AppGlobalState> = {
  core: coreReducer,
  router: routerReducer,
  routerExtra: routerExtraReducer,
  operators: operatorsReducer,
  customers: customersReducer,
  deviceTypes: deviceTypesReducer,
  functions: functionsReducer,
  ticketCategories: ticketCategoriesReducer,
  um: umReducer,
  costCenter: costCenterReducer,
  costAllocationTypes: costAllocationTypesReducer,
  role: roleReducer,
  dashboard: dashboardReducer,
  datiPersonalizzati: datiPersonalizzatiReducer,
  priorities: priorityReducer,
  ticketStates: ticketStatesReducer,
  jobTypes: jobTypesReducer,
  documentType: documentTypeReducer,
  licenses: licensesReducer,
  widgets: widgetReducer,
  'settings-core': settingsCoreReducer,
  'item-types': itemTypesReducer
};

export const StoreEffects = [
  CoreEffects,
  AuthEffects,
  RouterEffects,
  OperatorsEffect,
  CustomersEffect,
  DeviceTypesEffect,
  FunctionsEffect,
  TicketCategoriesEffect,
  UmEffect,
  CostCenterEffect,
  RoleEffect,
  DashboardEffect,
  CostAllocationTypesEffect,
  DatoPersonalizzatoEffect,
  PriorityEffects,
  TicketStatesEffect,
  JobTypesEffects,
  DocumentTypeEffects,
  LicensesEffects,
  WidgetEffects,
  ItemTypesEffects,
  SettingsCoreEffect
];
