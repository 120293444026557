import { PreloadingStrategy, Route } from '@angular/router';
import { concatMap, Observable, of, timer } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Nullable } from '../../shared/models/types';

@Injectable({ providedIn: 'any' })
export class CustomPreloadingStrategy implements PreloadingStrategy {
  readonly #logger = inject(NGXLogger);
  readonly #loadedModules: Set<string> = new Set();
  #baseTime = 0;

  public preload (route: Route, load: () => Observable<unknown>): Observable<unknown> {
    let result: Observable<unknown> = of(null);
    if ((route.data != null) && route.data['preload']) {
      result = timer((route.data['delay'] ?? 0) + this.#baseTime).pipe(
        concatMap(() => {
          const moduleName = route.data?.['moduleName'] as Nullable<string>;
          if(moduleName && !this.#loadedModules.has(moduleName)) {
            this.#loadedModules.add(moduleName);
            this.#logger.log('Loading now', route.data?.['moduleName'] ?? 'lazy', 'module/component');
          }
          return load();
        }));
      this.#baseTime += 500;
    }
    return result;
  }
}
