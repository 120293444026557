import { createReducer, on } from '@ngrx/store';
import { LicensesApiActions } from './licenses.actions';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { Nullable } from '../../../shared/models/types';
import { cloneDeep } from 'lodash-es';

export interface LicensesState {
  allAssignedUsernames: string[]
  isLoading: boolean
  httpStatus: Nullable<HttpStatus>
}

const licensesInitialState: LicensesState = {
  allAssignedUsernames: [],
  isLoading: false,
  httpStatus: null
};

export const licensesReducer = createReducer(
  licensesInitialState,

  on(LicensesApiActions.getAllAssignedUsers, (state) => ({ ...state, isLoading: false })),
  on(LicensesApiActions.getAllAssignedUsersSuccess, (state, { users }) => ({ ...state, allAssignedUsernames: [...users], isLoading: false })),
  on(LicensesApiActions.getAllAssignedUsersFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false }))
);
