import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import * as TicketStatesAction from './ticket-states.action';
import { map, switchMap } from 'rxjs';
import { TicketStatesService } from '../services/ticket-states.service';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'any' })
export class TicketStatesEffect extends NgRxEffectsBase {
  private readonly ticketStatesService = inject(TicketStatesService);

  public getAll$ = createEffect(() => this.actions$.pipe(
    ofType(TicketStatesAction.getAll),
    switchMap(() => this.ticketStatesService.getAllTicketStates(true).pipe(
      map(states => TicketStatesAction.getAllSucces({ states })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(TicketStatesAction.getAllFailure({ httpStatus: null }))
    ))
  ));
}
