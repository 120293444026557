export type BroadcastChannelType = 'global-state' | 'token' | 'sw-updates';

export enum BroadcastMessageType {
  SET_STATE,
  DISPATCH_ACTION,
  UPDATE_TOKEN
}

export interface BroadcastMessage<T> {
  /*
    Per ora il valore sarà sempre tagappweb ma in ottica di multi tenant sarà cruciale definire l'origine del messaggio.
    Se tagappweb venisse servito sotto il dominio generico www.tagapplication.it,
    ogni cliente accederebbe alla propria istanza sfruttando un percorso dedicato (es. alpha tauri avrebbe www.tagapplication.it/alpha-tauri).
    In quest'ottica è necessario che il parametro origin contenga il valore alpha-tauri perché altrimenti si rischia di mescolare i dati dei vari clienti.
    E' il caso limite in cui su due schede si aprono le applicazioni di due clienti diversi.
    Immaginiamo ad esempio il team Consulting che fa assistenza su Marelli e Alpha Tauri e apre due schede sullo stesso browser.
    In caso di origin non corretto i messaggi inviati dal BroadcastChannel verrebbero utilizzati da entrambe le schede.
  */
  origin: string
  type: BroadcastMessageType;
  payload: T;
}