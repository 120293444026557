import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivateFn,
  createUrlTreeFromSnapshot,
  GuardResult,
  MaybeAsync,
  Router
} from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';
import * as CustomersSelectors from '../../../core/customers/store/customers.selector';
import { filterNotNull } from "../../../shared/utility/rxjs-utils";


export const canViewCustomerAssets: CanActivateFn = (route: ActivatedRouteSnapshot): MaybeAsync<GuardResult> => {
  const store = inject(Store);
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);
  return store.select(CustomersSelectors.customersDtoXLoggedOpe(false, null)).pipe(
    filterNotNull,
    filter(customers => customers.length > 0),
    map(customers => {
      const tree = createUrlTreeFromSnapshot(activatedRoute.snapshot, []);
      const customerId = route.queryParams['cId'];
      const isActive = customers.find(cu => cu.id === Number(customerId))?.active;
      let path: any[] = [];
      if (route.data['outletModal']) {
        if ('primary' in tree.root.children) {
          path = [{ outlets: { modal: null, primary: tree.root.children['primary'].toString() } }];
        } else {
          path = [{ outlets: { modal: null } }];
        }
      } else {
        path = ['asset'];
      }

      return (!isActive && customerId != null) ? router.createUrlTree(path,{ queryParams: { ...route.queryParams, cId: null, dId: null } }) : true;
    })
  );
};