import { createEffect, ofType } from '@ngrx/effects';
import { inject, Injectable } from '@angular/core';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { WidgetApiActions } from './widget.actions';
import { map, mergeMap, Observable } from 'rxjs';
import { WidgetService } from '../services/widget.service';
import { Action } from '@ngrx/store';
import { WidgetDTO, WidgetFilterItem } from '../widget.model';
import { FilterRequest } from '../../filter-search/FilterSearch';

@Injectable({ providedIn: 'any' })
export class WidgetEffects extends NgRxEffectsBase {

  readonly #widgetService = inject(WidgetService);

  public getByWebOperationCode$ = createEffect(() => this.actions$.pipe(
    ofType(WidgetApiActions.getByModule),
    mergeMap(({ module }) => this.#widgetService.getByWebOperationCode(module, true).pipe(
      map(widgets => WidgetApiActions.getByModuleSuccess({ widgets, module })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(WidgetApiActions.getByModuleFailure({ httpStatus: null }))
    ))
  ));

  public getFilterDataSourceByWidget$ = createEffect(() => this.actions$.pipe(
    ofType(WidgetApiActions.getFilterDataSourceByWidget),
    mergeMap(({ widget, filterRequest, searchValue }) => {
      return this.buildFilterItemRequest(widget, filterRequest, searchValue).pipe(
        map(dataSource => WidgetApiActions.getFilterDataSourceByWidgetSuccess({ dataSource, entityType: widget.entityType })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(WidgetApiActions.getFilterDataSourceByWidgetFailure({ httpStatus: null }))
      );
    })
  ));

  private buildFilterItemRequest (widget: WidgetDTO, filterRequest?: FilterRequest, searchValue?: string): Observable<WidgetFilterItem[]> {
    let obs$: Observable<WidgetFilterItem[]>;
    switch (widget.entityType) {
      case 'TicketState':
        obs$ = this.#widgetService.getFilterItemsByWidgetId(widget.id, filterRequest, searchValue, false);
        break;
      case 'TicketCreator':
        obs$ = this.#widgetService.getMyTicketsFilterItems(widget.id, filterRequest, searchValue);
        break;
      default:
        obs$ = this.#widgetService.getFilterItemsByWidgetId(widget.id, filterRequest, searchValue);
        break;
    }
    return obs$;
  }
}
