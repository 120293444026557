import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { CostCenterService } from '../services/cost-center.service';
import * as CostCenterActions from './cost-center.actions';
import { map, switchMap } from 'rxjs';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'any' })
export class CostCenterEffect extends NgRxEffectsBase {
  private readonly costCenterService = inject(CostCenterService);

  public getAll$ = createEffect(() => this.actions$.pipe(
    ofType(CostCenterActions.getAll),
    switchMap(() => this.costCenterService.getAllCostCenters().pipe(
      map(costCenters => CostCenterActions.getAllSuccess({ costCenters })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(CostCenterActions.getAllFailure({ httpStatus: null }))
    ))
  ));

}
