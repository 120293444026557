import { createReducer, on } from '@ngrx/store';
import { UnitaDiMisuraDTO } from '../um.model';
import { UmApiActions, UmDefaultActions } from './um.action';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { Nullable } from '../../../shared/models/types';
import { cloneDeep } from 'lodash-es';
import { produce } from 'immer';

export interface UMState {
  umList: UnitaDiMisuraDTO[] // Lista di tutte le unità di misura disponibili
  isLoading: boolean // Indica se è in corso un caricamento
  httpStatus: Nullable<HttpStatus>
}

export const umInitialState: UMState = {
  umList: [],
  isLoading: false,
  httpStatus: null
};

export const umReducer = createReducer(
  umInitialState,

  on(UmApiActions.getAll, (state) => ({ ...state, isLoading: true })),
  on(UmApiActions.getAllSuccess, (state, { umList }) => ({ ...state, umList: [...umList], isLoading: false })),
  on(UmApiActions.getAllFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false })),

  on(UmApiActions.getUMById, UmApiActions.updateUM, (state) => ({ ...state, isLoading: true })),
  on(UmApiActions.getUMByIdSuccess, UmApiActions.updateUMSuccess, (state, { um }) => produce(state, draft => {
    draft.isLoading = false;
    draft.umList = draft.umList.map(c => {
      if(um.default){
        c.default = false;
      }
      if(um.id === c.id){
        c = um;
      }
      return c;
    });
  })),
  on(UmApiActions.getUMByIdFailure, UmApiActions.updateUMFailure,  (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false })),

  on(UmApiActions.createUM, (state) => ({ ...state, isLoading: true })),
  on(UmApiActions.createUMSuccess, (state, { um }) => produce(state, draft => {
    draft.isLoading = false;
    if(um.default) {
      draft.umList = draft.umList.map(c => {
        if (um.default) {
          c.default = false;
        }
        return c;
      });
    }
    draft.umList.push(um);
  })),
  on(UmApiActions.createUMFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false })),


  on(UmApiActions.deleteUM, (state) => ({ ...state, isLoading: true })),
  on(UmApiActions.deleteUMSuccess, (state, { id }) => ({ ...state, umList: state.umList.filter(c => c.id !== id), isLoading: false  })),
  on(UmApiActions.deleteUMFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false  })),

  on(UmDefaultActions.clearErrors, (state) => ({ ...state, httpStatus: null })),
  on(UmDefaultActions.clearState, () => ({ ...umInitialState }))
);
