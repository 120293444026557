import { inject, Injectable } from '@angular/core';
import { FunctionService } from '../services/function.service';
import { createEffect, ofType } from '@ngrx/effects';
import { FunctionsApiActions } from './functions.action';
import { catchError, map, mergeMap, switchMap } from 'rxjs';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';
import { delayRequest } from '../../../shared/utility/rxjs-utils';
import * as RouterActions from '../../router/store/router.actions';
import { translate } from '@jsverse/transloco';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { CoreDefaultActions } from '../../store/core.actions';
import { OperatorForOperatorService } from "../../operators/services/operator-for-operator.service";

@Injectable({ providedIn: 'any' })
export class FunctionsEffect extends NgRxEffectsBase {

  readonly #functionService = inject(FunctionService);
  readonly #operatorXOperatorService = inject(OperatorForOperatorService);

  public getAllFunctions$ = createEffect(() => this.actions$.pipe(
    ofType(FunctionsApiActions.getAll),
    switchMap(() => this.#functionService.getAllFunctions().pipe(
      map(functionList => FunctionsApiActions.getAllSuccess({ functionList })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(FunctionsApiActions.getAllFailure({ httpStatus: null }))
    ))
  ));

  public getAllByOperator$ = createEffect(() => this.actions$.pipe(
    ofType(FunctionsApiActions.getAllForOperator),
    switchMap(() => this.#functionService.getFunctionsByOperator().pipe(
      map(functionsForOperator => FunctionsApiActions.getAllForOperatorSuccess({ functionsForOperator })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(FunctionsApiActions.getAllForOperatorFailure({ httpStatus: null }))
    ))
  ));

  public getById$ = createEffect(() => this.actions$.pipe(
    ofType(FunctionsApiActions.getById),
    switchMap(({ id }) => this.#functionService.getById(id, false).pipe(
      map(func => FunctionsApiActions.getByIdSuccess({ func })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(FunctionsApiActions.getByIdFailure({ httpStatus: null }))
    ))
  ));

  public create$ = createEffect(() => this.actions$.pipe(
    ofType(FunctionsApiActions.create),
    switchMap(({ func, redirectToDetail, actions }) => delayRequest(this.#functionService.create(func)).pipe(
      mergeMap(func => {
        let result: Action[] = [FunctionsApiActions.createSuccess({ func })];
        if(redirectToDetail) {
          result.push(RouterActions.go({
            path: ['settings/users-groups/list/edit/group/', `${func.id}`],
            extras: { queryParamsHandling: 'merge' }
          }));
        }
        if(actions) {
          result = [...result, ...actions];
        }
        return result;
      }),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(FunctionsApiActions.createFailure({
        httpStatus: {
          type: 'error',
          text: translate('errorCreateGroup')
        }
      }))
    ))
  ));

  public update$ = createEffect(() => this.actions$.pipe(
    ofType(FunctionsApiActions.update),
    switchMap(({ func, actions }) => delayRequest(this.#functionService.update(func)).pipe(
      mergeMap(func => [
        FunctionsApiActions.updateSuccess({ func }),
        ...(actions ?? [])
      ]),
      catchError((e: HttpErrorResponse) => {
        const httpStatus: HttpStatus = {
          type: 'error',
          text: translate('errorUpdateGroup')
        };
        const actions: Action[] = [];
        if(e.status === HttpStatusCode.Conflict) {
          httpStatus.text = '';
          const [title, msg1, msg2, msg3] = this.translocoService.translate(['warning', 'dataIsNotUpToDate', 'askLoadUpdatedData', 'allChangesWillBeLost']);
          actions.push(
            CoreDefaultActions.confirmThenDoAction({
              title: `${title}!`,
              msg: `<p class="tw-pb-3">${msg1}. ${msg2}</p><p>${msg3}</p>`,
              actions: [FunctionsApiActions.getById({ id: func.id })]
            })
          );
        }
        return [FunctionsApiActions.updateFailure({ httpStatus }), ...actions ];
      })
    ))
  ));

  public delete$ = createEffect(() => this.actions$.pipe(
    ofType(FunctionsApiActions.delete),
    switchMap(({ id }) => delayRequest(this.#functionService.delete(id)).pipe(
      map(() => FunctionsApiActions.deleteSuccess({ id })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(FunctionsApiActions.deleteFailure({
        httpStatus: {
          type: 'error',
          text: translate('errorDeleteGroup')
        }
      }))
    ))
  ));

  public refreshExecutors$ = createEffect(() => this.actions$.pipe(
    ofType(FunctionsApiActions.refreshExecutors),
    switchMap(() => delayRequest(this.#operatorXOperatorService.emptyQueueOperatorsForOperators()).pipe(
      map(( ) => FunctionsApiActions.refreshExecutorsSuccess()),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(FunctionsApiActions.refreshExecutorsFailure({
        httpStatus: {
          type: 'error',
          text: translate('errorResetData')
        }
      }))
    ))
  ));
}
