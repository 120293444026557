import { createReducer, on } from '@ngrx/store';
import { DocumentTypeDTO } from '../document-type.model';
import { DocumentTypeApiActions, DocumentTypeDefaultActions } from './document-type.actions';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { Nullable } from '../../../shared/models/types';
import { clone, cloneDeep } from 'lodash-es';
import { produce } from "immer";
import { ArrayUtils } from "../../../shared/utility/ArrayUtils";

export interface DocumentTypeState {
  documentTypes: DocumentTypeDTO[]
  isLoading: boolean
  httpStatus: Nullable<HttpStatus>
}

const initialState: DocumentTypeState = {
  documentTypes: [],
  isLoading: false,
  httpStatus: null
};

export const documentTypeReducer = createReducer(
  initialState,

  on(DocumentTypeApiActions.getAll, (state) => ({ ...state, isLoading: true })),
  on(DocumentTypeApiActions.getAllSuccess, (state, { documentTypes }) => ({ ...state, documentTypes: [...documentTypes], isLoading: false })),
  on(DocumentTypeApiActions.getAllFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false })),


  on(DocumentTypeApiActions.getDocumentTypeById, (state) => ({ ...state, isLoading: true })),
  on(DocumentTypeApiActions.getDocumentTypeByIdSuccess, (state, { documentType }) => produce(state, draft => {
    draft.isLoading = false;
    draft.documentTypes = ArrayUtils.updateArrayByObjProp(draft.documentTypes, 'id', documentType);
  })),
  on(DocumentTypeApiActions.getDocumentTypeByIdFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false  }) ),

  on(DocumentTypeApiActions.create, (state) => ({ ...state, isLoading: true })),
  on(DocumentTypeApiActions.createSuccess, (state, { documentType }) => ({
    ...state,
    documentTypes: [...state.documentTypes, documentType].map(d => {
      const t = clone(d);
      if(documentType.default && d.id !== documentType.id){
        t.default = false;
      }
      return t;
    }),
    isLoading: false
  })),
  on(DocumentTypeApiActions.createFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false })),

  on(DocumentTypeApiActions.update, (state) => ({ ...state, isLoading: true })),
  on(DocumentTypeApiActions.updateSuccess, (state, { documentType }) => ({
    ...state,
    documentTypes: state.documentTypes.map(d => {
      let t = clone(d);
      if(documentType.default){
        t.default = false;
      }
      if(d.id === documentType.id) {
        t = clone(documentType);
      }
      return t;
    }),
    isLoading: false
  })),
  on(DocumentTypeApiActions.updateFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false })),

  on(DocumentTypeApiActions.delete, (state) => ({ ...state, isLoading: true })),
  on(DocumentTypeApiActions.deleteSuccess, (state, { id }) => ({ ...state, documentTypes: state.documentTypes.filter(d => d.id !== id), isLoading: false })),
  on(DocumentTypeApiActions.deleteFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false })),

  on(DocumentTypeDefaultActions.clearErrors, (state) => ({ ...state, httpStatus: null })),
  on(DocumentTypeDefaultActions.clearState, () => ({ ...initialState }))
);
