import { CostCenterDTO } from '../cost-center.model';
import { createReducer, on } from '@ngrx/store';
import * as CostCenterActions from './cost-center.actions';
import * as CostCenterSettingsActions from './../../../features/settings/store/actions/cost-centers.actions';

export interface CostCenterState {
  costCenterList: CostCenterDTO[]
  isLoading: boolean
}

export const costCenterInitialState: CostCenterState = {
  costCenterList: [],
  isLoading: false
};

export const costCenterReducer = createReducer(
  costCenterInitialState,

  on(CostCenterActions.getAll, (state) => ({ ...state, isLoading: true })),
  on(CostCenterActions.getAllSuccess, CostCenterSettingsActions.getAllCostCentersSuccess, (state, { costCenters }) => ({ ...state, costCenterList: [...costCenters], isLoading: false })),
  on(CostCenterActions.getAllFailure, (state) => ({ ...state, isLoading: false })),

  on(CostCenterActions.clearState, () => ({ ...costCenterInitialState }))
);
