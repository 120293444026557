import { createReducer, on } from '@ngrx/store';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { Nullable } from '../../../shared/models/types';
import { cloneDeep } from 'lodash-es';
import { ItemTypesApiActions, ItemTypesDefaultActions } from './item-types.actions';
import { produce } from 'immer';
import { ItemTypeDTO } from '../items.model';


export interface ItemTypesState {
  itemTypesList: ItemTypeDTO[]
  isLoading: boolean
  httpStatus: Nullable<HttpStatus>
}

const itemTypesInitialState: ItemTypesState = {
  itemTypesList: [],
  isLoading: false,
  httpStatus: null
};

export const itemTypesReducer = createReducer(
  itemTypesInitialState,

  on(ItemTypesApiActions.getAllItemTypes, (state) => ({ ...state, isLoading: true })),
  on(ItemTypesApiActions.getAllItemTypesSuccess, (state, { itemTypes }) => ({ ...state, itemTypesList: [...itemTypes], isLoading: false })),
  on(ItemTypesApiActions.getAllItemTypesFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus) })),

  on(ItemTypesApiActions.createItemTypes, (state) => ({ ...state, isLoading: true })),
  on(ItemTypesApiActions.createItemTypesSuccess, (state, { itemType }) => produce (state, draft => {
    draft.isLoading = false;
    draft.itemTypesList = draft.itemTypesList.map(i => {
      if(itemType.default){
        i.default = false;
      }
      return i;
    });
    draft.itemTypesList.push(itemType);
  })),
  on(ItemTypesApiActions.createItemTypesFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus) })),

  on(ItemTypesApiActions.updateItemTypes,ItemTypesApiActions.getItemTypesById, (state) => ({ ...state, isLoading: true })),
  on(ItemTypesApiActions.getItemTypesByIdSuccess,ItemTypesApiActions.updateItemTypesSuccess, (state, { itemType }) => produce(state, draft => {
    draft.isLoading = false;
    draft.itemTypesList = draft.itemTypesList.map(i => {
      if(itemType.default){
        i.default = false;
      }
      if (itemType.id === i.id){
        i = itemType;
      }
      return i;
    });
  })),
  on(ItemTypesApiActions.updateItemTypesFailure, ItemTypesApiActions.getItemTypesByIdFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false })),

  on(ItemTypesApiActions.deleteItemTypes, (state) => ({ ...state, isLoading: true })),
  on(ItemTypesApiActions.deleteItemTypesSuccess, (state, { id }) => produce(state, draft => {
    draft.itemTypesList = draft.itemTypesList.filter(i => i.id !== id);
    draft.isLoading = false;
  })),
  on(ItemTypesApiActions.deleteItemTypesFailure, (state, { httpStatus }) => produce(state, draft => {
    draft.httpStatus = httpStatus;
    draft.isLoading = false;
  })),

  on(ItemTypesDefaultActions.clearState, () => ({ ...itemTypesInitialState })),
  on(ItemTypesDefaultActions.clearErrors, (state) => ({ ...state, httpStatus: null }))
);
