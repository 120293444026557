import { createReducer, on } from "@ngrx/store";
import { CoreDefaultActions } from "./core.actions";
import { CalendarApiActions } from "../../features/calendar/store/actions/calendar.actions";

export interface CoreState {
  navbarTitleKey: string; // Chiave di traduzione per il modulo
  sessionExpired: boolean;
  isLoading: boolean
}

const initialState: CoreState = {
  navbarTitleKey: '',
  sessionExpired: false,
  isLoading: false
};

const isLoadingTrueActions = [
  CalendarApiActions.createUserFilter, CalendarApiActions.updateUserFilter, CalendarApiActions.deleteUserFilter,
];

const isLoadingFalseActions = [
  CalendarApiActions.createUserFilterSuccess, CalendarApiActions.createUserFilterFailure,
  CalendarApiActions.updateUserFilterSuccess, CalendarApiActions.updateUserFilterFailure,
  CalendarApiActions.deleteUserFilterSuccess, CalendarApiActions.deleteUserFilterFailure,
];

export const coreReducer = createReducer(
  initialState,

  on(CoreDefaultActions.toggleMainLoader, (state, { show }) => ({ ...state, isLoading: show })),
  on(CoreDefaultActions.setNavbarTitleKey, (state, { navbarTitleKey }) => ({ ...state, navbarTitleKey })),
  on(CoreDefaultActions.setSessionExpired, (state, { sessionExpired }) => ({ ...state, sessionExpired })),

  on(...isLoadingTrueActions, (state) => ({ ...state, isLoading: true })),
  on(...isLoadingFalseActions, (state) => ({ ...state, isLoading: false })),
);