import { inject, NgModule } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

@NgModule()
export class DisableBackButtonModule {
  private readonly locationStrategy = inject(LocationStrategy);
  private readonly router = inject(Router);

  constructor () {
    // Previene la possibilità di tornare indietro
    this.setHistoryState();
    // Controlla se vengono premuti i pulsanti avanti/indietro.
    this.locationStrategy.onPopState(() => {
      this.setHistoryState();
    });
    // Aggiorno la history ad ogni cambio di route
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setHistoryState();
      }
    });
  }

  private setHistoryState (): void {
    window.history.pushState(null, '', window.location.href);
  }
}
