import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { CostAllocationTypesService } from '../services/cost-allocation-types.service';
import * as CostAllocationTypesActions from '../../cost-allocation-types/store/cost-allocation-types.action';
import { map, mergeMap, switchMap } from 'rxjs';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'any' })
export class CostAllocationTypesEffect extends NgRxEffectsBase {
  private readonly costAllocationTypesService = inject(CostAllocationTypesService);

  public findAll$ = createEffect(() => this.actions$.pipe(
    ofType(CostAllocationTypesActions.findAll),
    switchMap(() => this.costAllocationTypesService.getAllCostAllocationTypes().pipe(
      map(costAllocationTypes => CostAllocationTypesActions.findAllSuccess({ costAllocationTypes })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(
        CostAllocationTypesActions.findAllFailure({ httpStatus: null }),
        'Errore nel recupero di tutte le tipologie di costo'
      )
    ))
  ));

  public findAllByScope$ = createEffect(() => this.actions$.pipe(
    ofType(CostAllocationTypesActions.findAllByScope),
    mergeMap(({ scope }) => this.costAllocationTypesService.getCostAllocationTypesByScope(scope).pipe(
      map(costAllocationTypes => CostAllocationTypesActions.findAllByScopeSuccess({ scope, costAllocationTypes })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(
        CostAllocationTypesActions.findAllByScopeFailure({ httpStatus: null }),
        `Errore nel recupero delle tipologie di costo per lo scope: ${scope}`
      )
    ))
  ));

}
