import { KeycloakConfig } from 'keycloak-js/dist/keycloak';
import { getConfigAsync } from "../settings/config-loader";

let settings: KeycloakConfig | null = null;

export const getSettings = (): KeycloakConfig => settings as KeycloakConfig;

export const getSettingsAsync = async (): Promise<KeycloakConfig> => {
  if (settings == null) {
    const config = await getConfigAsync();
    if(config){
      settings = config.sso;
    }
  }
  return settings as KeycloakConfig;
};
