import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { UmApiActions } from './um.action';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { UmService } from '../services/um.service';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';
import { delayRequest } from '../../../shared/utility/rxjs-utils';
import * as RouterActions from '../../router/store/router.actions';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { translate } from '@jsverse/transloco';
import { CoreDefaultActions } from '../../store/core.actions';

@Injectable({ providedIn: 'any' })
export class UmEffect extends NgRxEffectsBase {
  readonly #umService = inject(UmService);

  public getAll$ = createEffect(() => this.actions$.pipe(
    ofType(UmApiActions.getAll),
    switchMap(() => this.#umService.getAllUm().pipe(
      map(umList => UmApiActions.getAllSuccess({ umList })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(
        UmApiActions.getAllFailure({ httpStatus: null }),
        'Errore nel recupero di tutte le unità di misura'
      )
    ))
  ));


  public getUMById$ = createEffect(() => this.actions$.pipe(
    ofType(UmApiActions.getUMById),
    switchMap(({ id }) => delayRequest(this.#umService.getUmById(id)).pipe(
      map(um => UmApiActions.getUMByIdSuccess({ um })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(UmApiActions.getUMByIdFailure({ httpStatus: null }))
    ))
  ));

  public createUM$ = createEffect(() => this.actions$.pipe(
    ofType(UmApiActions.createUM),
    switchMap(({ um, redirectToDetail, actions }) => delayRequest(this.#umService.createUm(um)).pipe(
      mergeMap(um => {
        let result: Action[] = [UmApiActions.createUMSuccess({ um })];
        if(redirectToDetail) {
          result.push(RouterActions.go({ path: ['settings/measure-unit/list/edit', `${um.id}`], extras: { queryParamsHandling: 'merge' } }));
        }
        if(actions) {
          result = [...result, ...actions];
        }
        return result;
      }),
      catchError((err: HttpErrorResponse) => of(UmApiActions.createUMFailure({
        httpStatus: {
          type: 'error',
          text: this.translocoService.translate('errorCreateUM')
        }
      })))
    ))
  ));

  public updateUM$ = createEffect(() => this.actions$.pipe(
    ofType(UmApiActions.updateUM),
    switchMap(({ um, actions }) => delayRequest(this.#umService.updateUm(um)).pipe(
      mergeMap((um) => [UmApiActions.updateUMSuccess({ um }), ...(actions ?? [])]),
      catchError((err: HttpErrorResponse) => {
        const httpStatus: HttpStatus = {
          type: 'error',
          text: this.translocoService.translate('errorUpdateUM')
        };
        const actions: Action[] = [];
        if (err.status === HttpStatusCode.Conflict){
          httpStatus.text = '';
          const [title, msg1, msg2, msg3] = this.translocoService.translate(['warning', 'dataIsNotUpToDate', 'askLoadUpdatedData', 'allChangesWillBeLost']);
          actions.push(
            CoreDefaultActions.confirmThenDoAction({
              title: `${title}!`,
              msg: `<p class="tw-pb-3">${msg1}. ${msg2}</p><p>${msg3}</p>`,
              actions: [UmApiActions.getUMById({ id: um.id })]
            })
          );
        }
        return [UmApiActions.updateUMFailure({ httpStatus }), ...actions];
      })
    ))
  ));

  public deleteUMById$ = createEffect(() => this.actions$.pipe(
    ofType(UmApiActions.deleteUM),
    switchMap(({ id }) => delayRequest(this.#umService.deleteUmById(id)).pipe(
      map(() => UmApiActions.deleteUMSuccess({ id })),
      this.rxjsUtils.advancedCatchErrorWithLog<Action>((e: HttpErrorResponse) => {
        let text = translate('errorDeleteUM');
        if(e.status === HttpStatusCode.Forbidden) {
          text = translate('errorDeleteSettingsRelations', { entity: text });
        }
        return of(UmApiActions.deleteUMFailure({ httpStatus: { type: 'error', text } }));
      })
    ))
  ));

}
