import { IconDefinition } from "@fortawesome/free-regular-svg-icons";

export const faGrid: IconDefinition = {
  prefix: "fas",
  iconName: "grid",
  icon: [488,512,[],'','M0 72c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40V72zm0 160c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40v-48zm128 160v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40v-48c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40zm32-320c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40V72zm128 160v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40v-48c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40zM160 392c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40v-48zM448 72v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40V72c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40zM320 232c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40v-48zm128 160v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40v-48c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40z']
};

export const faNcIco: IconDefinition = {
  prefix: "fas",
  // @ts-expect-error - .
  iconName: "nc",
  icon: [512,512,[],'','m405 85l-89 0c-9-24-32-42-60-42-28 0-51 18-60 42l-89 0c-24 0-43 19-43 43l0 299c0 23 19 42 43 42l298 0c24 0 43-19 43-42l0-299c0-24-19-43-43-43z m-170 299l-107 0 0-43 107 0z m0-128l-107 0 0-43 107 0z m21-128c-12 0-21-10-21-21 0-12 9-22 21-22 12 0 21 10 21 22 0 11-9 21-21 21z m128 247l-30 30-23-23-23 23-31-30 24-23-24-23 31-30 23 23 23-23 30 30-23 23z m-55-119l-52 0 0-43 34 0 43-42 30 30z']
};
