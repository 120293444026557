import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { ItemTypeService } from '../services/item-type.service';
import { ItemTypesApiActions } from './item-types.actions';
import { Action } from '@ngrx/store';
import { delayRequest } from '../../../shared/utility/rxjs-utils';
import { translate } from '@jsverse/transloco';
import * as RouterActions from '../../router/store/router.actions';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { CoreDefaultActions } from '../../store/core.actions';


@Injectable({ providedIn: 'any' })
export class ItemTypesEffects extends NgRxEffectsBase {

  readonly #itemTypesService = inject(ItemTypeService);

  public getAllItemTypes$ = createEffect(() => this.actions$.pipe(
    ofType(ItemTypesApiActions.getAllItemTypes),
    switchMap(() => delayRequest(this.#itemTypesService.getAll()).pipe(
      map( itemTypes => ItemTypesApiActions.getAllItemTypesSuccess({ itemTypes })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(ItemTypesApiActions.getAllItemTypesFailure({ httpStatus: null }))
    ))
  ));

  public getItemTypeById$ = createEffect(() => this.actions$.pipe(
    ofType(ItemTypesApiActions.getItemTypesById),
    switchMap(({ id }) => this.#itemTypesService.getById(id).pipe(
      map(itemType => ItemTypesApiActions.getItemTypesByIdSuccess({ itemType })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(ItemTypesApiActions.getItemTypesByIdFailure({ httpStatus: null }))
    ))
  ));

  public createItemType$ = createEffect(() => this.actions$.pipe(
    ofType(ItemTypesApiActions.createItemTypes),
    switchMap(({ itemType, redirectToDetail, actions }) => delayRequest(this.#itemTypesService.create(itemType)).pipe(
      mergeMap( itemType => {
        let result: Action[] = [ItemTypesApiActions.createItemTypesSuccess({ itemType })];
        if (redirectToDetail) {
          result.push(RouterActions.go({ path: ['settings/items-types/list/edit', `${itemType.id}`], extras: { queryParamsHandling: 'merge' } }));
        }
        if (actions) {
          result = [...result, ...actions];
        }
        return result;
      }),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(ItemTypesApiActions.createItemTypesFailure({ httpStatus: null }))
    ))
  ));

  public updateItemType$ = createEffect(() => this.actions$.pipe(
    ofType(ItemTypesApiActions.updateItemTypes),
    switchMap(({ itemType }) => delayRequest(this.#itemTypesService.update(itemType)).pipe(
      map( itemType => ItemTypesApiActions.updateItemTypesSuccess({ itemType })),
      catchError((e: HttpErrorResponse) => {
        const httpStatus: HttpStatus = {
          type: 'error',
          text: translate('errorUpdateItemType')
        };
        const actions: Action[] = [];
        if(e.status === HttpStatusCode.Conflict) {
          httpStatus.text = '';
          const [title, msg1, msg2, msg3] = this.translocoService.translate(['warning', 'dataIsNotUpToDate', 'askLoadUpdatedData', 'allChangesWillBeLost']);
          actions.push(
            CoreDefaultActions.confirmThenDoAction({
              title: `${title}!`,
              msg: `<p class="tw-pb-3">${msg1}. ${msg2}</p><p>${msg3}</p>`,
              actions: [ItemTypesApiActions.getItemTypesById( { id: itemType.id })]
            })
          );
        }
        return [ItemTypesApiActions.updateItemTypesFailure({ httpStatus }), ...actions ];
      })
    )),
  ));

  public deleteItemType$ = createEffect(() => this.actions$.pipe(
    ofType(ItemTypesApiActions.deleteItemTypes),
    switchMap(({ id }) => delayRequest(this.#itemTypesService.delete(id)).pipe(
      map(() => ItemTypesApiActions.deleteItemTypesSuccess({ id })),
      this.rxjsUtils.advancedCatchErrorWithLog<Action>((e: HttpErrorResponse) => {
        let text = translate('errorDeleteItemType');
        if(e.status === HttpStatusCode.Forbidden) {
          text = translate('errorDeleteSettingsRelations', { entity: text });
        }
        return of(ItemTypesApiActions.deleteItemTypesFailure({ httpStatus: { type: 'error', text } }));
      })
    ))
  ));
}

