import { inject, Injectable } from '@angular/core';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { SettingsService } from '../services/settings.service';
import { createEffect, ofType } from '@ngrx/effects';
import { SettingsCoreAction } from '../store/settings-core.action';
import { map, mergeMap, switchMap } from 'rxjs';
import { translate } from '@jsverse/transloco';
import { Action } from '@ngrx/store';
import { delayRequest } from '../../../shared/utility/rxjs-utils';

@Injectable({ providedIn: 'any' })
export class SettingsCoreEffect extends NgRxEffectsBase {

  readonly #settingsService = inject(SettingsService);

  public getTasksSettings = createEffect(() => this.actions$.pipe(
    ofType(SettingsCoreAction.getTasksSettings),
    switchMap(() => {
      const logError = translate('errorGetTaskSettings');
      return this.#settingsService.getSettingsTaskDTO().pipe(
        map(settings => SettingsCoreAction.getTasksSettingsSuccess({ settings })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(
          SettingsCoreAction.getTasksSettingsFailure({ httpStatus: null }),
          logError
        )
      );
    })
  ));

  public updateTasksSettings = createEffect(() => this.actions$.pipe(
    ofType(SettingsCoreAction.updateTasksSettings),
    switchMap(({ settings }) => delayRequest(this.#settingsService.updateSettingsTaskDTO(settings)).pipe(
      mergeMap((settings) => [ SettingsCoreAction.updateTasksSettingsSuccess({ settings }) ]),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(
        SettingsCoreAction.updateTasksSettingsFailure({
          httpStatus: {
            type: 'error',
            text: translate('errorUpdateTasksSettings')
          }
        })
      )
    ))
  ));

  public getDocumentsSettings = createEffect(() => this.actions$.pipe(
    ofType(SettingsCoreAction.getDocumentsSettings),
    switchMap(() => {
      const logError = translate('errorGetDocumentsSettings');
      return this.#settingsService.getSettingsDocumentDTO().pipe(
        map(settings => SettingsCoreAction.getDocumentsSettingsSuccess({ settings })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(
          SettingsCoreAction.getDocumentsSettingsFailure({ httpStatus: null }),
          logError
        )
      );
    })
  ));

  public updateDocumentsSettings = createEffect(() => this.actions$.pipe(
    ofType(SettingsCoreAction.updateDocumentsSettings),
    switchMap(({ settings }) => delayRequest(this.#settingsService.updateSettingsDocumentDTO(settings)).pipe(
      map((settings) => SettingsCoreAction.updateDocumentsSettingsSuccess({ settings })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(
        SettingsCoreAction.getDocumentsSettingsFailure({
          httpStatus: {
            type: 'error',
            text: translate('errorUpdateDocumentsSettings')
          }
        })
      )
    ))
  ));

  public getTicketSettings$ = createEffect(() => this.actions$.pipe(
    ofType(SettingsCoreAction.getTicketSettings),
    switchMap(() => {
      return this.#settingsService.getSettingsTicketDTO().pipe(
        map(settings => SettingsCoreAction.getTicketSettingsSuccess({ settings })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(SettingsCoreAction.getTicketSettingsFailure({ httpStatus: null }))
      );
    })
  ));

  public updateTicketSettings$ = createEffect(() => this.actions$.pipe(
    ofType(SettingsCoreAction.updateTicketSettings),
    switchMap(({ settings }) => delayRequest(this.#settingsService.updateSettingsTicketDTO(settings)).pipe(
      map((settings) => SettingsCoreAction.updateTicketSettingsSuccess({ settings })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(
        SettingsCoreAction.updateTicketSettingsFailure({
          httpStatus: {
            type: 'error',
            text: translate('errorUpdateTicketSettings')
          }
        })
      )
    ))
  ));

}
