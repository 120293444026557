import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { JobTypeService } from '../../services/job-type.service';
import * as JobTypesActions from '../job-types/job-types.actions';
import { map, switchMap } from 'rxjs';
import { NgRxEffectsBase } from '../../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'any' })
export class JobTypesEffects extends NgRxEffectsBase {
  private readonly jobTypesService = inject(JobTypeService);

  public allJobTypes$ = createEffect(() => this.actions$.pipe(
    ofType(JobTypesActions.allJobTypes),
    switchMap(() => this.jobTypesService.getAll(false).pipe(
      map(jobTypes => JobTypesActions.allJobTypesSuccess({ jobTypes })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(
        JobTypesActions.allJobTypesFailure({ httpStatus: null }),
        'Errore nel recupero di tutte le tipologie di attività'
      )
    ))
  ));
}
