import { AppRoute } from './app.model';
import { canMatchRole, canMatchRoleDisplay } from './core/guards/role.guard';
import { filterEnabledRoutes } from './shared/utility/utility';
import { loggedOperatorGuard, notLoggedOperatorGuard, redirectUserHomeGuard } from './core/guards/auth.guard';
import { LoaderPageComponent } from './shared/components/loader/loader-page.component';
import { ReportWrapperComponent } from './features/report/components/report-wrapper.component';
import { TicketWrapperComponent } from './features/ticket-detail/components/ticket-wrapper.component';
import { CostWrapperComponent } from './features/cost/components/cost-wrapper.component';
import { AssetDetailWrapperComponent } from './features/asset-detail/components/asset-detail-wrapper.component';
import { GuidService } from './core/services/gui/guid.service';
import { canViewCustomerAssets } from './features/assets/guards/assets.guard';
import { TranslocoService } from "@jsverse/transloco";
import { inject } from "@angular/core";
import { map } from "rxjs";
import { MaybeAsync } from "@angular/router";
import { NewTicketWrapperComponent } from "./shared/components/ticket/new-ticket-wrapper.component";
import { Store } from "@ngrx/store";
import { CoreDefaultActions } from "./core/store/core.actions";

const titleBuilder = (navbarTitleKey: string): MaybeAsync<string> => {
  inject(Store).dispatch(CoreDefaultActions.setNavbarTitleKey({ navbarTitleKey }));
  return inject(TranslocoService).selectTranslate<string>(navbarTitleKey).pipe(
    map(title => `Tag Application - ${title}`)
  );
};

export const AppRoutes: AppRoute[] = filterEnabledRoutes([
  {
    path: 'my-profile', loadChildren: () => import('./features/my-profile/my-profile-routing').then(m => m.MyProfileRoutes),
    title: () => titleBuilder('myprofile'), data: { showAppLoader: true }, enableInProd: true,
    canActivate: [loggedOperatorGuard]
  },
  {
    path: 'asset', loadChildren: () => import('./features/assets/assets-routing').then(m => m.AssetsRoutes),
    title: () => titleBuilder('assets'), data: { showAppLoader: true, pageCaller: 'Assets' },
    canActivate: [canViewCustomerAssets], canMatch: [canMatchRoleDisplay(['my-profile'])], enableInProd: true
  },
  {
    path: 'asset', component: AssetDetailWrapperComponent, providers: [GuidService],
    loadChildren: () => import('./features/asset-detail/asset-detail-routing').then(m => m.AssetDetailRoutes({ outletModal: false, windowOpen: true })),
    data: { showAppLoader: true, outletModal: false }, enableInProd: true, canActivate: [loggedOperatorGuard]
  },
  {
    path: 'asset', component: AssetDetailWrapperComponent, providers: [GuidService],
    loadChildren: () => import('./features/asset-detail/asset-detail-routing').then(m => m.AssetDetailRoutes({ outletModal: true })),
    data: { showAppLoader: true, outletModal: true }, enableInProd: true, outlet: 'modal', canActivate: [canViewCustomerAssets, loggedOperatorGuard]
  },
  {
    path: 'asset-direct', component: AssetDetailWrapperComponent, providers: [GuidService],
    loadChildren: () => import('./features/asset-detail/asset-detail-routing').then(m => m.AssetDetailRoutes({ outletModal: false, windowOpen: true })),
    data: { showAppLoader: true, outletModal: false, windowOpen: true }, enableInProd: true, canActivate: [canViewCustomerAssets, loggedOperatorGuard]
  },
  {
    path: 'asset-change-type', outlet: 'modal', enableInProd: true,
    loadChildren: () => import('./features/asset-change-type/asset-change-type-routing').then(m => m.AssetChangeTypeRoutes({ outlet: 'modal' })),
    data: { showAppLoader: true }, canMatch: [canMatchRole([{ outlets: { modal: null, primary: ['asset'] } }], 'modification', 'Assets')]
  },
  {
    path: 'asset-change-type', outlet: 'second-modal', enableInProd: true,
    loadChildren: () => import('./features/asset-change-type/asset-change-type-routing').then(m => m.AssetChangeTypeRoutes({ outlet: 'second-modal' })),
    data: { showAppLoader: false }, canMatch: [canMatchRole([{ outlets: { modal: null, 'second-modal': null, primary: ['asset'] } }], 'modification', 'Assets')]
  },
  {
    path: 'dashboard', loadChildren: () => import('./features/dashboard/dashboard-routing').then(m => m.DashboardRoutes),
    title: () => titleBuilder('dashboard'), data: { showAppLoader: true, pageCaller: 'Dashboard' },
    canMatch: [canMatchRoleDisplay(['my-profile'])], enableInProd: true
  },
  {
    path: 'job', loadChildren: () => import('./features/job/job-routing').then(m => m.JobRoutes(false)),
    data: { pageCaller: 'Job', preload: true, delay: 2000, moduleName: 'Job' },
    enableInProd: true, canMatch: [canMatchRoleDisplay(['my-profile'])]
  },
  {
    path: 'job', loadChildren: () => import('./features/job/job-routing').then(m => m.JobRoutes(true)),
    data: { pageCaller: 'Job', preload: true, delay: 2000, moduleName: 'Job' },
    enableInProd: true, outlet: 'modal', canMatch: [canMatchRoleDisplay(['my-profile'])]
  },
  {
    path: 'monitoring', loadChildren: () => import('./features/monitoring/monitoring-routing').then(m => m.MonitoringRoutes),
    title: () => titleBuilder('monitoring'), data: { showAppLoader: true, pageCaller: 'IoT' },
    enableInProd: true, canMatch: [canMatchRoleDisplay(['my-profile'])]
  },
  {
    path: 'settings', loadChildren: () => import('./features/settings/settings-routing').then(m => m.SettingsRoutes),
    title: () => titleBuilder('settings'), data: { showAppLoader: true, pageCaller: 'Settings' },
    enableInProd: true, canMatch: [canMatchRoleDisplay(['my-profile'])]
  },
  {
    path: 'planner', loadChildren: () => import('./features/planner/planner-routing').then(m => m.PlannerRoutes),
    title: () => titleBuilder('planner'), data: { showAppLoader: true, pageCaller: 'Planner' },
    canMatch: [canMatchRoleDisplay(['my-profile'])], enableInProd: true
  },
  {
    path: 'calendar', loadChildren: () => import('./features/calendar/calendar-routing').then(m => m.CalendarRoutes),
    title: () => titleBuilder('calendar'), data: { showAppLoader: true, pageCaller: 'Calendar' },
    enableInProd: true, canMatch: [canMatchRoleDisplay(['my-profile'])]
  },
  /* MODULO TICKET */
  {
    path: 'ticket', loadChildren: () => import('./features/ticket/ticket-routing').then(m => m.TicketRoutes),
    title: () => titleBuilder('ticket'), data: { showAppLoader: true, pageCaller: 'Ticket' },
    enableInProd: true, canMatch: [canMatchRoleDisplay(['my-profile'])]
  },
  /* POPUP NUOVO TICKET */
  {
    path: 'ticket', component: NewTicketWrapperComponent,
    children: [{ path: 'new', loadComponent: () => import('./features/ticket/components/new-ticket-page.component').then(c => c.NewTicketPageComponent) }],
    data: { pageCaller: 'Ticket' }, enableInProd: true, outlet: 'modal', canMatch: [canMatchRoleDisplay(['my-profile'])]
  },
  /* POPUP DETTAGLIO TICKET */
  {
    path: 'ticket', component: TicketWrapperComponent,
    loadChildren: () => import('./features/ticket-detail/ticket-detail-routing').then(m => m.TicketDetailRoutes({ jobUrl: 'job', outletModal: true, isTicketModule: false })),
    data: { pageCaller: 'Ticket' },
    enableInProd: true, outlet: 'modal', canMatch: [canMatchRoleDisplay(['my-profile'])]
  },
  /* POPUP TRASFERISCI / CHIUDI TICKET */
  {
    path: 'ticket', loadChildren: () => import('./features/ticket-detail/ticket-detail-routing').then(m => m.TicketTransferCloseRoutes('second-modal')),
    data: { pageCaller: 'Ticket' }, enableInProd: true, outlet: 'second-modal', canMatch: [canMatchRoleDisplay(['my-profile'])]
  },
  {
    path: 'items', loadChildren: () => import('./features/items/items-routing').then(m => m.ItemsRoutes),
    title: () => titleBuilder('items'), data: { showAppLoader: true, pageCaller: 'Items' },
    enableInProd: true, canMatch: [canMatchRoleDisplay(['my-profile'])]
  },
  {
    path: 'cost', loadChildren: () => import('./features/cost/cost-routing').then(m => m.CostRoutes),
    enableInProd: true, canActivate: [loggedOperatorGuard]
  },
  {
    path: 'cost', component: CostWrapperComponent, loadChildren: () => import('./features/cost/cost-routing').then(m => m.CostRoutes),
    enableInProd: true, outlet: 'modal', canActivate: [loggedOperatorGuard]
  },
  {
    path: 'report', loadChildren: () => import('./features/report/report-routing').then(m => m.ReportRoutes(false)),
    enableInProd: true, canActivate: [loggedOperatorGuard]
  },
  {
    path: 'report', component: ReportWrapperComponent, loadChildren: () => import('./features/report/report-routing').then(m => m.ReportRoutes(true)),
    enableInProd: true, outlet: 'modal', canActivate: [loggedOperatorGuard]
  },
  {
    path: 'report', component: ReportWrapperComponent, loadChildren: () => import('./features/report/report-routing').then(m => m.ReportRoutes(true)),
    enableInProd: true, outlet: 'second-modal', canActivate: [loggedOperatorGuard]
  },
  {
    path: 'non-compliance', loadChildren: () => import('./features/non-compliance/non-compliance-routing').then(m => m.NonComplianceRoutes),
    title: () => titleBuilder('nonCompliance'), data: { showAppLoader: true, pageCaller: 'NonCompliance' },
    enableInProd: true, canMatch: [canMatchRoleDisplay(['my-profile'])]
  },
  {
    path: 'consultations', loadChildren: () => import('./features/consultations/consultations-routing').then(m => m.ConsultationsRoutes),
    title: () => titleBuilder('consultations'), data: { showAppLoader: true, pageCaller: 'Consultations' },
    enableInProd: true, canMatch: [canMatchRoleDisplay(['my-profile'])]
  },
  { path: 'login-error', loadComponent: () => import('./features/login/login-failure.component').then(c => c.LoginFailureComponent), enableInProd: true },
  {
    path: 'reset-password', loadComponent: () => import('./features/login/password-reset-page.component').then(c => c.PasswordResetPageComponent), enableInProd: true,
    canActivate: [notLoggedOperatorGuard]
  },
  { path: 'test', loadChildren: () => import('./features/test/test-routing').then(m => m.TestRoutes), enableInProd: false },
  // Il componente LoaderPageComponent è fittizio in quanto non verrà mai caricato perché il canMatch eseguirà il redirect verso la home dell'utente
  { path: '', component: LoaderPageComponent, enableInProd: true, canMatch: [redirectUserHomeGuard] },
  { path: '**', component: LoaderPageComponent, enableInProd: true, canMatch: [redirectUserHomeGuard] } // Route for a 404 page or homePage
]);
