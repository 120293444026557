import { inject, Injectable } from '@angular/core';
import { TemplateDatoPersService } from '../services/template-dato-pers.service';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';
import { DatoPersonalizzatoApiActions } from './dato-personalizzato.actions';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';
import { delayRequest } from '../../../shared/utility/rxjs-utils';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import * as RouterActions from '../../router/store/router.actions';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { translate } from '@jsverse/transloco';
import { CoreDefaultActions } from '../../store/core.actions';

@Injectable({ providedIn: 'any' })
export class DatoPersonalizzatoEffect extends NgRxEffectsBase {
  private readonly datoPersonalizzatoService = inject(TemplateDatoPersService);

  public allTemplateDatoPers$ = createEffect(() => this.actions$.pipe(
    ofType(DatoPersonalizzatoApiActions.allTemplateDatoPers),
    switchMap(() => this.datoPersonalizzatoService.getAllTemplateDatoPers().pipe(
      map(datiPersonalizzati => DatoPersonalizzatoApiActions.allTemplateDatoPersSuccess({ datiPersonalizzati })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(DatoPersonalizzatoApiActions.allTemplateDatoPersFailure({ httpStatus: null }))
    ))
  ));

  public getTemplateDatoPers$ = createEffect(() => this.actions$.pipe(
    ofType(DatoPersonalizzatoApiActions.getTemplateDatoPersById),
    switchMap(({ id }) => delayRequest(this.datoPersonalizzatoService.getTemplateDatoPersById(id)).pipe(
      map(template => DatoPersonalizzatoApiActions.getTemplateDatoPersByIdSuccess({ template })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(DatoPersonalizzatoApiActions.getTemplateDatoPersByIdFailure({ httpStatus: null }))
    ))
  ));

  public createTemplateDatoPers$ = createEffect(() => this.actions$.pipe(
    ofType(DatoPersonalizzatoApiActions.createTemplateDatoPers),
    switchMap(({ template, redirectToDetail,  actions }) => delayRequest(this.datoPersonalizzatoService.createTemplateDatoPers(template)).pipe(
      mergeMap(template => {
        let result: Action[] = [DatoPersonalizzatoApiActions.createTemplateDatoPersSuccess({template})];
        if (redirectToDetail) {
          result.push(RouterActions.go({ path: ['settings/templates/list/edit', `${template.id}`], extras: {queryParamsHandling: 'merge'} }));
        }
        if (actions) {
          result = [...result, ...actions];
        }
        return result;
      }),
      catchError((err: HttpErrorResponse) => of(DatoPersonalizzatoApiActions.createTemplateDatoPersFailure({
        httpStatus: {
          type: 'error',
          text: this.translocoService.translate('errorCreateTemplateDatoPers')
        }
      })))
    ))
  ));

  public updateTemplateDatoPers$ = createEffect(() => this.actions$.pipe(
    ofType(DatoPersonalizzatoApiActions.updateTemplateDatoPers),
    switchMap(({ template, actions }) => delayRequest(this.datoPersonalizzatoService.updateTemplateDatoPers(template)).pipe(
      mergeMap((template) => [DatoPersonalizzatoApiActions.updateTemplateDatoPersSuccess({ template }), ...(actions ?? [])]),
      catchError((err: HttpErrorResponse) => {
        const httpStatus: HttpStatus = {
          type: 'error',
          text: this.translocoService.translate('errorUpdateTemplateDatoPers')
        };
        const actions: Action[] = [];
        if (err.status === HttpStatusCode.Conflict){
          httpStatus.text = '';
          const [title, msg1, msg2, msg3] = this.translocoService.translate(['warning', 'dataIsNotUpToDate', 'askLoadUpdatedData', 'allChangesWillBeLost']);
          actions.push(
            CoreDefaultActions.confirmThenDoAction({
              title: `${title}!`,
              msg: `<p class="tw-pb-3">${msg1}. ${msg2}</p><p>${msg3}</p>`,
              actions: [DatoPersonalizzatoApiActions.getTemplateDatoPersById({ id: `${template.id}` })]
            })
          );
        }
        return [DatoPersonalizzatoApiActions.updateTemplateDatoPersFailure({ httpStatus }), ...actions];
      })
    ))
  ));

  public deleteTemplateDatoPersById$ = createEffect(() => this.actions$.pipe(
    ofType(DatoPersonalizzatoApiActions.deleteTemplateDatoPers),
    switchMap(({ id }) => delayRequest(this.datoPersonalizzatoService.deleteTemplateDatoPers(id)).pipe(
      map(() => DatoPersonalizzatoApiActions.deleteTemplateDatoPersSuccess({ id })),
      this.rxjsUtils.advancedCatchErrorWithLog<Action>((e: HttpErrorResponse) => {
        let text = translate('errorDeleteTemplateDatoPers');
        if(e.status === HttpStatusCode.Forbidden) {
          text = translate('errorDeleteSettingsRelations', { entity: text });
        }
        return of(DatoPersonalizzatoApiActions.deleteTemplateDatoPersFailure({ httpStatus: { type: 'error', text } }));
      })
    ))
  ));
}
