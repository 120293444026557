import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment as Config } from '../../../../environments/environment';
import { forkJoin, map, Observable } from 'rxjs';
import { WidgetDTO, WidgetFilterItem } from '../widget.model';
import { Nullable } from '../../../shared/models/types';
import { WebOperationCode } from '../../role/role.model';
import { FilterRequest } from '../../filter-search/FilterSearch';
import { translate } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class WidgetService {

  private readonly http = inject(HttpClient);
  private readonly basePath = `${Config.apiBaseUrl}/widget`;

  public getByWebOperationCode (code: WebOperationCode, onlyActive: boolean): Observable<WidgetDTO[]> {
    const params = new HttpParams().appendAll({ onlyActive });
    return this.http.get<Nullable<WidgetDTO[]>>(`${this.basePath}/module/${code}`, { params }).pipe(
      map(v => v ?? [])
    );
  }

  public getFilterItemsByWidgetId (id: string, filterRequest?: FilterRequest, searchValue?: string, defaultFilter?: boolean): Observable<WidgetFilterItem[]> {
    let params = new HttpParams();
    if(searchValue != null){
      params = params.append('searchValue', searchValue);
    }
    if(defaultFilter != null){
      params = params.append('defaultFilter', defaultFilter);
    }
    return this.http.post<Nullable<WidgetFilterItem[]>>(`${this.basePath}/filter/${id}`, filterRequest ?? {}, { params }).pipe(
      map(v => v ?? [])
    );
  }

  // Caso specifico per il widget di tipo i miei ticket.
  // In questo caso vengono concatenate due chiamate
  public getMyTicketsFilterItems (id: string, filterRequest?: FilterRequest, searchValue?: string): Observable<WidgetFilterItem[]> {
    return forkJoin({
      myTickets: this.getFilterItemsByWidgetId(id, { ...filterRequest, myTickets: true, myAssignedTicket: false, myObservedTickets: false }, searchValue).pipe(
        map(v => ({ ...v[0], id: 'myTickets', text: translate(('myTickets')) }))
      ),
      myAssignedTickets: this.getFilterItemsByWidgetId(id, { ...filterRequest, myAssignedTicket: true, myTickets: false, myObservedTickets: false }, searchValue).pipe(
        map(v => ({ ...v[0], id: 'myAssignedTicket', text: translate(('assignedTicketsToMe')) }))
      ),
      myObservedTickets: this.getFilterItemsByWidgetId(id, { ...filterRequest, myObservedTickets: true, myAssignedTicket: false, myTickets: false }, searchValue).pipe(
        map(v => ({ ...v[0], id: 'myObservedTickets', text: translate(('myObservedTickets')) }))
      )
    }).pipe(map(({ myTickets, myAssignedTickets, myObservedTickets }) => {
      const result: WidgetFilterItem[] = [];
      if(myTickets.count > 0){
        result.push(myTickets);
      }
      if(myAssignedTickets.count > 0){
        result.push(myAssignedTickets);
      }
      if(myObservedTickets.count > 0){
        result.push(myObservedTickets);
      }
      return result;
    }));
  }

}
