/* eslint-disable */
// eslint disattivato in quanto si tratta di un'interfaccia recuperata dalla libreria HammerJs

import { HammerGestureConfig } from '@angular/platform-browser'
import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'any' })
export class HammerConfig extends HammerGestureConfig {
  public override overrides = {
    swipe: {
      direction: Hammer.DIRECTION_ALL
    }
  } as any

  public override buildHammer (element: HTMLElement) {
    let options = {}

    // @ts-expect-error
    if (element.attributes['data-mc-options']) {
      try {
        // @ts-expect-error
        options = JSON.parse(element.attributes['data-mc-options'].nodeValue)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('An error occurred when attempting to parse Hammer.js options: ', err)
      }
    }

    const mc = new Hammer(element, options)

    // retain support for angular overrides object
    for (const eventName of Object.keys(this.overrides)) {
      mc.get(eventName).set(this.overrides[eventName])
    }

    return mc
  }
}
