import { WebOperationDTO } from '../role.model';
import { createReducer, on } from '@ngrx/store';
import * as RoleActions from './role.action';

export interface RoleState {
  webOperations: WebOperationDTO[]
  isLoading: boolean
}

export const roleInitialState: RoleState = {
  webOperations: [],
  isLoading: false
};

export const roleReducer = createReducer(
  roleInitialState,

  on(RoleActions.getAllWebOperations, (state) => ({ ...state, isLoading: true })),
  on(RoleActions.getAllWebOperationsSuccess, (state, { webOperations }) => ({ ...state, webOperations: [...webOperations], isLoading: false })),
  on(RoleActions.getAllWebOperationsFailure, (state) => ({ ...state, isLoading: false })),

  on(RoleActions.clearState, () => ({ ...roleInitialState }))
);
