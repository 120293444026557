import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { PriorityService } from '../service/priority.service';
import * as PriorityActions from './priority.actions';
import { map, switchMap } from 'rxjs';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'any' })
export class PriorityEffects extends NgRxEffectsBase {

  private readonly priorityService = inject(PriorityService);

  public getAll$ = createEffect(() => this.actions$.pipe(
    ofType(PriorityActions.getAll),
    switchMap(() => this.priorityService.getAllPriorities().pipe(
      map(priorities => PriorityActions.getAllSuccess({ priorities })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(PriorityActions.getAllFailure({ httpStatus: null }))
    ))
  ));

}
