import { createReducer, on } from '@ngrx/store';
import { TicketStatesDTO } from '../ticket-states.model';
import * as TicketStatesActions from './ticket-states.action';

export interface TicketStatesState {
  stateList: TicketStatesDTO[]
  isLoading: boolean
}

const ticketStatesInitialState: TicketStatesState = {
  stateList: [],
  isLoading: false
};

export const ticketStatesReducer = createReducer(
  ticketStatesInitialState,

  on(TicketStatesActions.getAll, (state) => ({ ...state, isLoading: true })),
  on(TicketStatesActions.getAllSucces, (state, { states }) => ({ ...state, stateList: [...states], isLoading: false })),
  on(TicketStatesActions.getAllFailure, (state) => ({ ...state, isLoading: false })),

  on(TicketStatesActions.clearState, () => ({ ...ticketStatesInitialState }))
);
