import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '../auth/services/auth.service';
import { Store } from '@ngrx/store';
import * as RouterActions from '../router/store/router.actions';
import * as RouterSelectors from '../router/store/router.selectors';
import * as CoreSelectors from '../store/core.selectors';
import { Router } from '@angular/router';
import { NGXLogger } from "ngx-logger";

export const BaseHttpInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const auth = inject(AuthService);
  const store = inject(Store);
  const router = inject(Router);
  const logger = inject(NGXLogger);
  const embeddedStore = store.selectSignal(RouterSelectors.embedded)();
  const sessionExpired = store.selectSignal(CoreSelectors.sessionExpired)();

  if(sessionExpired) {
    return throwError(() => new HttpErrorResponse({
      error: 'Session expired',
      status: 440,
      statusText: 'Session expired'
    }));
  }

  return next(req).pipe(
    tap(() => {
      const params = router.getCurrentNavigation()?.initialUrl.queryParams;
      if ((params != null) && 'embedded' in params) {
        const embedded = params['embedded'] === 'true';
        if(embeddedStore !== embedded) {
          store.dispatch(RouterActions.setEmbedded({ embedded }));
        }
      }
    }),
    catchError((err: HttpErrorResponse) => {
      let result = throwError(() => err);
      if(req.url.includes('login')) {
        if (err.status === HttpStatusCode.Unauthorized || (err.status === HttpStatusCode.NotFound)) {
          result = of();
          auth.logout();
        } else if (err.status === HttpStatusCode.UnavailableForLegalReasons) {
          result = of();
          logger.warn('Cambio password necessario');
          router.navigate(['reset-password']);
        }
      }
      return result;
    })
  );
};
