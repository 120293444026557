import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { JobWrapperComponent } from '../job-wrapper/job-wrapper.component';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'ta-new-ticket-wrapper',
  standalone: true,
  template: `
    <ta-job-wrapper [visible]="true" dialogCssClass="tw-h-full lg:tw-h-[58rem] tw-w-full lg:tw-w-[46.5rem]">
      <router-outlet></router-outlet>
    </ta-job-wrapper>
  `,
  imports: [RouterOutlet, DialogComponent, JobWrapperComponent]
})
export class NewTicketWrapperComponent {}
