import { createAction, props } from '@ngrx/store';
import { PriorityDTO } from '../../ticket/ticket.model';
import { NgRxUtils } from '../../../shared/utility/NgRxUtils';

/* DEFAULT ACTIONS */
export const clearState = createAction('[Priority] clear state');

/* API ACTIONS */
export const getAll = createAction('[Priority/API] get all priorities');
export const getAllSuccess = createAction('[Priority/API] get all priorities success', props<{ priorities: PriorityDTO[] }>());
export const getAllFailure = NgRxUtils.createHttpStatusAction('[Priority/API] get all priorities failure');
