import { createReducer, on } from '@ngrx/store';
import * as CostAllocationTypesActions from './cost-allocation-types.action';
import { CostAllocationTypeScope, CostAllocationTypesDTO } from '../../cost-allocation/cost-allocation.model';
import { produce } from 'immer';

export interface CostAllocationTypesState {
  costAllocationTypesList: CostAllocationTypesDTO[]
  scopedCostAllocationTypes: Partial<Record<CostAllocationTypeScope, CostAllocationTypesDTO[]>>
  isLoading: boolean
}

export const costAllocationTypesInitialState: CostAllocationTypesState = {
  costAllocationTypesList: [],
  scopedCostAllocationTypes: {},
  isLoading: false
};

export const costAllocationTypesReducer = createReducer(
  costAllocationTypesInitialState,

  on(CostAllocationTypesActions.findAll, (state) => ({ ...state, isLoading: true })),
  on(CostAllocationTypesActions.findAllSuccess, (state, { costAllocationTypes }) => ({ ...state, costAllocationTypesList: [...costAllocationTypes], isLoading: false })),
  on(CostAllocationTypesActions.findAllFailure, (state) => ({ ...state, isLoading: false })),

  on(CostAllocationTypesActions.findAllByScope, (state) => ({ ...state, isLoading: true })),
  on(CostAllocationTypesActions.findAllByScopeSuccess, (state, { scope, costAllocationTypes }) => produce(state, draft => {
    draft.scopedCostAllocationTypes[scope] = costAllocationTypes;
    draft.isLoading = false;
  })),
  on(CostAllocationTypesActions.findAllByScopeFailure, (state) => ({ ...state, isLoading: false })),

  on(CostAllocationTypesActions.clearState, () => ({ ...costAllocationTypesInitialState }))
);
