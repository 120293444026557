import { createAction, props } from '@ngrx/store';
import { CostCenterDTO } from '../cost-center.model';
import { NgRxUtils } from '../../../shared/utility/NgRxUtils';

/* DEFAULT ACTIONS */
export const clearState = createAction('[CostCenter] clear state');

/* API ACTIONS */
export const getAll = createAction('[CostCenter/API] get all cost centers');
export const getAllSuccess = createAction('[CostCenter/API] get all cost centers success', props<{ costCenters: CostCenterDTO[] }>());
export const getAllFailure = NgRxUtils.createHttpStatusAction('[CostCenter/API] get all cost centers failure');