import { createAction, props } from '@ngrx/store';
import { JobTypeDTO } from '../../job.model';
import { NgRxUtils } from '../../../../shared/utility/NgRxUtils';

/* DEFAULT ACTIONS */
export const clearState = createAction('[JobTypes] clear state');

/* API ACTIONS */
export const allJobTypes = createAction('[JobTypes] get all job types');
export const allJobTypesSuccess = createAction('[JobTypes] get all job types success', props<{ jobTypes: JobTypeDTO[] }>());
export const allJobTypesFailure = NgRxUtils.createHttpStatusAction('[JobTypes] get all job types failure');
